var containerSwiper = new Swiper ('.swiper-container.full-slideshow, .swiper-container.thumbnail-slideshow', {
  loop: true,
  navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-prev',
  },
});

var fullSwiper = new Swiper ('.swiper-container.product-slideshow', {
  slidesPerView: 'auto',
  //centeredSlides: true,
  spaceBetween: 1,
  loop: true,
  navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-prev',
  },
});

$('.masonry-grid').masonry({
  // set itemSelector so .grid-sizer is not used in layout
  itemSelector: '.grid-item',
  // use element for option
  columnWidth: '.grid-sizer',
  percentPosition: true,
});